var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":_vm.imgLogo,"alt":"","height":"80"}})]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" Bienvenido ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Inicia sesión en su cuenta ")]),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Plataforma")])]),_c('v-select',{staticClass:"form-control-merge",attrs:{"label":"label","name":"type","reduce":function (option) { return option.id; },"options":[
                                    { id: 1, label: 'GEI' },
                                    { id: 2, label: 'VHE' }
                                ]},model:{value:(_vm.platform_id),callback:function ($$v) {_vm.platform_id=$$v},expression:"platform_id"}})],1),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"}},[_c('b-form-input',{attrs:{"id":"login-email","name":"login-email","placeholder":"Email"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1)],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Contraseña")])]),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","type":_vm.passwordFieldType,"name":"login-password","placeholder":"Contraseña"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.loading}},[(_vm.loading)?_c('b-spinner',{attrs:{"label":"Cargando..."}}):_c('span',[_vm._v("Iniciar sesión")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }